import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Typography } from '@mui/material';
import i18n from '../../../../../i18n';
import { renderTextField } from '../../../../../components/ReduxFormFields';
import { Tooltip } from '../../../../../components';

const TextField = ({ name, label, showTooltip, validate = [], parse, labelColor }) => (
  <div className="d-flex align-items-center justify-content-between">
    <Typography variant="h4" sx={{ color: labelColor || 'text.primary', maxWidth: '300px' }}>
      {i18n.t(label)}
      {showTooltip && (
        <>
          {' '}
          <Tooltip title={i18n.t(showTooltip)} placement="right" />
        </>
      )}
    </Typography>
    <Field
      InputProps={{
        disableUnderline: true,
        sx: {
          backgroundColor: '#1313130D',
          borderRadius: '4px',
          p: '4px 20px',
          maxWidth: '120px',
          maxHeight: '54px',
          border: '1px solid #1313131A',
          '& > fieldset': { border: 'none' },
          input: { textAlign: 'center' }
        }
      }}
      validate={validate}
      parse={parse}
      name={name}
      component={renderTextField}
    />
  </div>
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showTooltip: PropTypes.string,
  validate: PropTypes.instanceOf(Object).isRequired,
  labelColor: PropTypes.string,
  parse: PropTypes.func.isRequired
};

export default TextField;
