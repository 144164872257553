import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import { LicenseFilter } from '../LicenseFilter';
import { filteredListOfOptions, signalOptions } from './constants';
import { dataListID } from '../../containers/Users/constants';
import { dataListSendRequest } from '../../containers/DataList/slice';
import { setPagesSettingQueries } from '../../containers/PagesSettings/slice';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { PrimaryButton } from '../UIKit';
import { APP_ID, EZEE_APP_ID } from '../../config';
import i18n from '../../i18n';

import './index.scss';
import { getDataListSelector } from '../../redux-store/selectors/dataList';
import { NEW_OEM_URL, PV_INSTALLER_URL } from '../../api/apiUrls';
import { getSignInUserRoleTypeSelector } from '../../redux-store/selectors/signIn';
import { multiselectConfig as style } from '../../constants';

const fixValue = (value) => {
  if (i18n.t('workStatusFilterPlaceholder') === value) {
    return undefined;
  }

  return value;
};

const AdditionalDataListFilters = (props) => {
  const { transformResponse, listURL, role } = props;
  const dispatch = useDispatch();
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const { [dataListID]: settings } = useSelector(getPagesSettingsSelector);
  const dataList = useSelector(getDataListSelector);
  const installerOptions = (dataList?.companiesFilter || dataList?.companies)?.data?.map((installer) => (installer?.my_company?.name ? ({ label: installer.my_company.name, _id: installer.my_company._id }) : ({ label: installer.name, _id: installer._id }))) || [];
  const oemOptions = dataList?.oems?.data?.filter((oem) => oem?.oem?.name).map((oem) => ({ label: oem.oem.name, _id: oem.oem._id }))?.sort((a, b) => a.label.localeCompare(b.label)) || [];
  const { isInstallationCompleted, supportContractFlag, hasErrors, rentedPVSystemFlag, signal } = settings.queries;
  const { oem = false } = settings.columns;

  const filterOptions = omit(settings.queries, ['_sort', '_limit', '_start', 'fields']);
  const disableButton = !Object.keys(filterOptions).some((key) => Boolean(settings.queries[key]));

  const handleChange = (data) => {
    dispatch(dataListSendRequest(
      {
        listID: dataListID,
        dataList: {
          listURL,
          params: { ...settings.queries, ...data },
          transformResponse
        }
      }
    ));
    dispatch(setPagesSettingQueries({ page: dataListID, data }));
  };

  const handleOemsChange = (selectedList) => {
    const oems = selectedList.map(({ _id }) => _id).join(',');
    handleChange({ oems });
  };

  const handleInstallersChange = (selectedList) => {
    const installers = selectedList.map(({ _id }) => _id).join(',');
    handleChange({ installers });
  };

  useEffect(() => {
    if (!oem) {
      handleChange({ oems: undefined });
    }

    if (myRoleType === 'oem') {
      dispatch(dataListSendRequest({
        listID: 'companiesFilter',
        dataList: {
          listURL: PV_INSTALLER_URL,
          transformResponse
        }
      }));
    } else if (oem && !['oem', 'property_management'].includes(role)) {
      dispatch(dataListSendRequest({
        listID: 'oems',
        dataList: {
          listURL: NEW_OEM_URL,
          transformResponse
        }
      }));
    }
  }, [oem]); // eslint-disable-line

  return (
    <div className="additional-filters__container px-4">
      <div className="support-contracts-filter__container">
        <span>
          {i18n.t('supportContracts')}
          :
        </span>
        <select
          className="additional-filters__select"
          onChange={(e) => handleChange({ supportContractFlag: fixValue(e.target.value) })}
          value={String(supportContractFlag)}
        >
          {filteredListOfOptions.map(({ value, key }) => (
            <option value={key} key={`supportContracts_${value}`}>
              {i18n.t(value)}
            </option>
          ))}
        </select>
      </div>
      {[EZEE_APP_ID].includes(APP_ID) && (
        <div className="support-contracts-filter__container">
          <span>
            {i18n.t('rentedSystem')}
            :
          </span>
          <select
            className="additional-filters__select"
            onChange={(e) => handleChange({ rentedPVSystemFlag: fixValue(e.target.value) })}
            value={String(rentedPVSystemFlag)}
          >
            {filteredListOfOptions.map(({ value, key }) => (
              <option value={key} key={`rentedPVSystemFlag_${value}`}>
                {i18n.t(value)}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="installation-finished-filter__container">
        <span>
          {i18n.t('installationFinished')}
          :
        </span>
        <select
          className="additional-filters__select"
          onChange={(e) => handleChange({ isInstallationCompleted: fixValue(e.target.value) })}
          value={String(isInstallationCompleted)}
        >
          {filteredListOfOptions.map(({ value, key }) => (
            <option value={key} key={`installationFinished_${value}`}>
              {i18n.t(value)}
            </option>
          ))}
        </select>
      </div>
      <div className="installation-finished-filter__container">
        <span>
          {i18n.t('signal')}
          :
        </span>
        <select
          className="additional-filters__select"
          onChange={(e) => handleChange({ signal: fixValue(e.target.value) })}
          value={String(signal)}
        >
          {signalOptions.map(({ value, key }) => (
            <option value={key} key={`signalOptions_${value}`}>
              {i18n.t(value)}
            </option>
          ))}
        </select>
      </div>
      <div className="error-filter__container">
        <span>
          {i18n.t('error')}
          :
        </span>
        <select
          className="additional-filters__select"
          onChange={(e) => handleChange({ hasErrors: fixValue(e.target.value) })}
          value={String(hasErrors)}
        >
          {filteredListOfOptions.map(({ value, key }) => (
            <option value={key} key={`error_${value}`}>
              {i18n.t(value)}
            </option>
          ))}
        </select>
      </div>
      {['root', 'solar_admin', 'oem'].includes(role) && (
        <div className="d-flex multi-select-wrapper">
          <div className="multi-select">
            <span>{`${i18n.t('installer')}:`}</span>
            <Multiselect
              options={installerOptions}
              placeholder={i18n.t('selectType')}
              showCheckbox
              emptyRecordMsg={i18n.t('noOptionsAvailable')}
              style={style}
              onSelect={handleInstallersChange}
              onRemove={handleInstallersChange}
              displayValue="label"
              selectedValues={installerOptions.filter((option) => (settings.queries.installers || []).includes(option._id))}
            />
          </div>
        </div>
      )}
      {oem && ['root', 'solar_admin'].includes(role) && (
        <div className="d-flex multi-select-wrapper">
          <div className="multi-select">
            <span>{`${i18n.t('oem')}:`}</span>
            <Multiselect
              options={oemOptions}
              placeholder={i18n.t('selectType')}
              showCheckbox
              open
              emptyRecordMsg={i18n.t('noOptionsAvailable')}
              style={style}
              onSelect={handleOemsChange}
              onRemove={handleOemsChange}
              displayValue="label"
              selectedValues={oemOptions.filter((option) => (settings.queries.oems || []).includes(option._id))}
            />
          </div>
        </div>
      )}
      <LicenseFilter
        onChange={handleChange}
        role={role}
        settings={settings}
      />
      <div className={`additional-filters__reset-btn ${disableButton && 'disable-button'}`}>
        <PrimaryButton
          // set all from filter options as undefined
          onClickHandler={() => handleChange(Object.keys(filterOptions).reduce((acc, key) => ({ ...acc, [key]: undefined }), {}))}
          i18nLabel="resetFilters"
          disabled={disableButton}
          isLargeButton
          customButtonClass="reset-filters-btn"
        />
      </div>
    </div>
  );
};

AdditionalDataListFilters.propTypes = {
  transformResponse: PropTypes.func,
  listURL: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};

export default AdditionalDataListFilters;
